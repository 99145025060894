// TaskForm.js
import React, { useState } from 'react';
import './TaskForm.css';

function TaskForm({ onAdd }) {
  const [taskName, setTaskName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (taskName.trim() === '') return;
    onAdd(taskName);
    setTaskName('');
  };

  return (
    <form onSubmit={handleSubmit} className="task-form">
      
      <button type="submit" className="add-task-button">+</button>
      <input
        type="text"
        value={taskName}
        onChange={(e) => setTaskName(e.target.value)}
        placeholder="Your next task..."
      />
    </form>
  );
}

export default TaskForm;
