import React from 'react';
import { AppUI } from './AppUI';
import 'react-circular-progressbar/dist/styles.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
/*
import {TodoCounter} from '../components/todoCounter/TodoCounter';
import {Header} from '../components/Header/Header';
import { Progressbar } from '../components/CircularProgressbar/Progressbar';
import {TodoSearch} from '../components/TodoSearch/TodoSearch';
import {TodoList} from '../components/TodoList/TodoList';
import {TodoItem} from '../components/TodoItem/TodoItem';
import {CreateTodoButton} from '../components/createTodoButton/CreateTodoButton';
import { Container } from '../components/container/Container';

*/
function App() {  

  return(
      <AppUI />
    );   
}
export default App;

  /*
  
  /* usa el hook useLocalStorage y ya no el modificador de estado, 
        Recibe 
        *el nombre item con que se guarda en localStorage.
        *estado inicial
  
        const{
          item: todos,
          saveItem: saveTodos,
          loading,
          error,} = useSavedMemory('TODOS_V1', []);
        //estado del componente search
        const [searchValue, setSearchValue] = React.useState('');
      
        //actualiza el estado del pie
        const [percentage, setPercentage] = React.useState(0);
      
        const completedTodos=todos.filter(todo=>!!todo.completed).length;
        const totalTodos = todos.length;
      
        //actuliza el progress pie
      
        useEffect(() => {
          if (totalTodos) {
          const porcen = ((completedTodos*100)/totalTodos); 
          setPercentage(porcen);
            } else {
              setPercentage(100);
          }
        }, [totalTodos, completedTodos]);
      
        //filtra las tareas buscadas
      
        const searchedTodos = todos.filter(
          (todo)=>{
          const todoText = todo.text.toLowerCase();
          const searchText = searchValue.toLowerCase();
          return todoText.includes(searchText);
        });
      
        // una tarea, marcarda como hecho
        const completeTodo = (text) => {
          const newTodos = [...todos];
          const todoIndex = newTodos.findIndex( (todo) => todo.text == text);
          if (!newTodos[todoIndex].completed) {
            newTodos[todoIndex].completed = true;
          }else{
            newTodos[todoIndex].completed = false;
          }
          
          saveTodos(newTodos);
        };
      
        //eliminar una tarea
        const deleteTodo = (text) => {
          const newTodos = [...todos];
          const todoIndex = newTodos.findIndex( (todo) => todo.text === text);
          newTodos.splice(todoIndex,1);
          saveTodos(newTodos);
        };
  */

  

    /*
    <React.Fragment>
      <Container >
      <Header/>
        <TodoCounter completed ={completedTodos} total ={totalTodos}/>
        <Progressbar percentage={percentage}/>
        
      <TodoSearch
      searchValue={searchValue}
      setSearchValue={setSearchValue}/>
      <TodoList>
          {searchedTodos.map(todo => (
            <TodoItem 
              key= {todo.text} 
              text = {todo.text}
              completed = {todo.completed}
              onComplete = {() => completeTodo(todo.text)}
              onDelete = {() => deleteTodo(todo.text)}
              />
              ))}
      </TodoList>
      <CreateTodoButton/>
      </Container>
    </React.Fragment> */

 
