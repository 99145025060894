import React, { useState } from 'react';
import TaskCompletionPie from './TaskCompletionPie';
import { ImCalendar, ImCoinDollar } from "react-icons/im";
import { MdCreate } from "react-icons/md";
import './ExpensesForm.css'; // Asegúrate de tener estilos para la estética de Apple
import { FaDollarSign, FaMinus,FaTimes, FaCar, FaHome, FaUtensils, FaBell, FaMusic, FaPaw, FaGift, FaTshirt, FaStore, FaTooth, FaHeartbeat, FaBus, FaCreditCard, FaPlane, FaPlus } from 'react-icons/fa';
import DoughnutChart from './DoughnutChart'; // Impor
import ExpensesList from './ExpensesList'
import { v4 as uuidv4 } from 'uuid';
import IncomesList from './IncomesList';
function ExpensesForm({ onClose, onAddEntry, incomes, expenses, onDelete, setExpenses, setIncomes }) {
  const [viewByMonth, setViewByMonth] = useState(false);
  const [showIncomeForm, setShowIncomeForm] = useState(false);
  const [showExpenseForm, setShowExpenseForm] = useState(false);
  const [incomeAmount, setIncomeAmount] = useState('');
  // Estados para el formulario de ingreso
  const [incomeNote, setIncomeNote] = useState('');
  const [incomeCategory, setIncomeCategory] = useState('');

  
  const [expenseAmount, setExpenseAmount] = useState('');
  const [amount, setAmount] = useState('');
  const [category, setCategory] = useState('');

  const [note, setNote] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Mes actual

  const filterByMonth = (entries) => {
    return entries.filter(entry => {
      const entryDate = new Date(entry.date);
      return entryDate.getMonth() === selectedMonth;
    });
  };

  const categories = [
    { icon: <FaCar className="icon-custom"/>, name: 'CAR' },
    { icon: <FaHome className="icon-custom"/>, name: 'HOME' },
    { icon: <FaUtensils className="icon-custom"/>, name: 'FOOD' },
    { icon: <FaBell className="icon-custom"/>, name: 'BILL' },
    { icon: <FaMusic className="icon-custom"/>, name: 'ENTE' },
    { icon: <FaPaw className="icon-custom"/>, name: 'PETS' },
    { icon: <FaGift className="icon-custom"/>, name: 'GIFT' },
    { icon: <FaTshirt className="icon-custom"/>, name: 'CLOT' },
    { icon: <FaStore className="icon-custom"/>, name: 'REST' },
    { icon: <FaTooth className="icon-custom"/>, name: 'HYGI' },
    { icon: <FaHeartbeat className="icon-custom"/>, name: 'HEAL' },
    { icon: <FaBus className="icon-custom"/>, name: 'TRAN' },
    { icon: <FaCreditCard className="icon-custom"/>, name: 'CARD' },
    { icon: <FaPlane className="icon-custom"/>, name: 'TRIP' },
    { icon: <FaTimes className="icon-custom"/>, name: 'OTHER' },
  ];  

  const icomecategories = [
    { icon: <ImCoinDollar className="icon-custom"/>, name: 'Salary' },
    { icon: <ImCalendar className="icon-custom"/>, name: 'Rent' },
    { icon: <FaTimes className="icon-custom"/>, name: 'Others' },
  ];  

  // Funciones para el teclado numérico
  function handleNumericInput(value) {
    setIncomeAmount(prev => prev + value);
  }

  function handleBackspace() {
    setIncomeAmount(prev => prev.slice(0, -1));
  }

  // Agregar función unificada para Ingreso y Gasto
 const handleAdd = (type) => {
  if (!amount || !category) return;

  const newEntry = {
    id: uuidv4(),
    amount: parseFloat(amount),
    category,
    date: new Date().toISOString(),
    type
  };

  onAddEntry(newEntry); // Cambiar onAddExpense por onAddEntry

  // Resetear campos y cerrar el formulario
  setAmount('');
  setCategory('');
  if (type === 'Income') setShowIncomeForm(false);
  else setShowExpenseForm(false);
};
const toggleView = () => {
  setViewByMonth(prev => !prev);  // Cambia entre mostrar por mes o total
};  
  // Filtra los gastos y los ingresos según el mes actual si viewByMonth es true
  const currentMonth = new Date().getMonth() + 1; // Mes actual (1-12)

  const filteredIncomes = viewByMonth
    ? incomes.filter(income => new Date(income.date).getMonth() + 1 === currentMonth)
    : incomes; // Si 'viewByMonth' es verdadero, filtra por mes actual

  const filteredExpenses = viewByMonth
    ? expenses
    : expenses.filter(expense => new Date(expense.date).getMonth() + 1 === currentMonth) ; // Filtra por mes actual si viewByMonth es true

  // Cálculos de totales
  const totalIncome = filteredIncomes.reduce((acc, curr) => acc + curr.amount, 0);
  const totalExpense = filteredExpenses.reduce((acc, curr) => acc + curr.amount, 0);
  const balance = totalIncome - totalExpense;
// Función para eliminar entradas (ingresos o gastos) del localStorage 

const handleDeleteEntry = (id, type) => {
  const updatedEntries = (type === 'Expense' ? expenses : incomes).filter(entry => entry.id !== id);
  if (type === 'Expense') {
    setExpenses(updatedEntries);
    localStorage.setItem('expenses', JSON.stringify(updatedEntries)); // Actualiza localStorage solo con las entradas necesarias
  } else {
    setIncomes(updatedEntries);
    localStorage.setItem('incomes', JSON.stringify(updatedEntries)); // Actualiza localStorage solo con las entradas necesarias
  }
};

  
  return (
  <>
    <header className="expenses-header">
    </header>
    <h1>My Finances</h1>

    <div>
      <h3 style={{ color: "#FF3B30" }}>Expense Tracker</h3>
      <button onClick={toggleView} className="showTtal">
        {viewByMonth ? 'Show This Month' : 'Show Total'}
      </button>
      {/* TaskCompletionPie Gráfico */}
      <div className="pie-chart-container">
        <DoughnutChart expenses={filteredExpenses} />
      </div>
    </div>
    {/* Balance */}
    <div className="balance-section">
    <h3>Balance</h3>
    <p>Total Incomes: ${totalIncome}</p>
    <p>Total Expenses: ${totalExpense}</p>
    <p>Balance: ${balance}</p>
    </div>
{/* Botones para agregar ingresos y egresos */} 
<div className="buttons-container"> 
  <button className="add-income-btn" 
  onClick={() => { setShowIncomeForm(true); 
  setShowExpenseForm(false); }}> <FaPlus /> Income </button> 
  <button className="add-expense-btn" 
  onClick={() => { setShowExpenseForm(true); 
  setShowIncomeForm(false); }}> <FaMinus /> Expense </button> 
  </div>

    {/* Formularios condicionales de ingresos/egresos */}
    {showIncomeForm && (<div className="form-container">
          <div className="input-group">
            <FaDollarSign className="dollar-icon" />
            <input 
              type="number" 
              value={amount} 
              onChange={(e) => setAmount(e.target.value)} 
              placeholder="Monto" 
            />
            <button className="grill" onClick={() => setAmount('')}><FaTimes /></button>
          </div>   
          <div className="input-group">
            <MdCreate />
            <input 
              type="text" 
              value={category} 
              onChange={(e) => {
                const value = e.target.value.toUpperCase();
                if (value.length <= 5) setCategory(value);
              }} 
              placeholder="New category, max 5 chars like..🏠RENT"
            />
              <button className="grill" onClick={(e) => setCategory('')}><FaTimes /></button>
          </div>
          <div className="categories-grid">
            {icomecategories.map((cat, index) => (
              <button 
                key={index} 
                className={`category-btn ${category ? 'disabled' : ''}`}
                onClick={() => setCategory(cat.name)}
              >
                {cat.icon}
                <span>{cat.name}</span>
              </button>
            ))}
            <button className="category-btn-add" onClick={() => handleAdd('Income')}>
              <FaPlus style={{  fontSize: '48px', fontWeight: 'bold' }}/>
              <span>Agregar</span>
            </button>
            
          </div>
        </div>
        )}

    {showExpenseForm && (
        <div className="form-container">
          <div className="input-group">
            <FaDollarSign className="dollar-icon" />
            <input 
              type="number" 
              value={amount} 
              onChange={(e) => setAmount(e.target.value)} 
              placeholder="Monto" 
            />
            <button className="grill" onClick={() => setAmount('')}><FaTimes /></button>
          </div>
          <div className="input-group">
            <MdCreate />
            <input 
              type="text" 
              value={category} 
              onChange={(e) => {
                const value = e.target.value.toUpperCase();
                if (value.length <= 5) setCategory(value);
              }} 
              placeholder="New category, max 5 chars..."
            />
              <button className="grill" onClick={(e) => setCategory('')}><FaTimes /></button>
          </div>
          <div className="categories-grid">
            {categories.map((cat, index) => (
              <button 
                key={index} 
                className={`category-btn ${category ? 'disabled' : ''}`}
                onClick={() => setCategory(cat.name)}
              >
                {cat.icon}
                <span>{cat.name}</span>
              </button>
            ))}
            <button className="category-btn-add" onClick={() => handleAdd('Expense')}>
              <FaPlus style={{  fontSize: '48px', fontWeight: 'bold' }}/>
              <span>Agregar</span>
            </button>
          </div>       
        </div>
    )}

    {/* Listas de ingresos y gastos */}
    <div className="select-month-container">
        <label htmlFor="month">Filter by Month:</label>
        <select 
          id="month" 
          value={selectedMonth} 
          onChange={(e) => setSelectedMonth(Number(e.target.value))}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i} value={i}>{new Date(0, i).toLocaleString('default', { month: 'long' })}</option>
          ))}
        </select>
      </div>

      <div className="lists-container">
        <div className="incomes-list"> 
          <IncomesList 
          incomes={filterByMonth(incomes)} 
          onDelete={onDelete} /> 
        </div>
      
        <div className="expenses-list">
          <ExpensesList 
            expenses={filterByMonth(expenses)} 
            onDelete={onDelete} />
        </div>
      </div>
    </>
  );
}

export default ExpensesForm;
