import React from 'react';
import { FaTrash } from 'react-icons/fa';

const IncomesList = ({ incomes, onDelete }) => {
  return (
    <div>
      <h3>Incomes</h3>
      <ul>
        {incomes.map((income, index) => (
          <li key={index}>
            ${income.amount} ... {income.category} ... {new Date(income.date).toLocaleDateString()}
            <button className="trash" onClick={() => onDelete(income.id, 'Income')}>
              <FaTrash />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IncomesList;
