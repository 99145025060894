// AppUI.js
import './App.css'; 
import '../index.css';
import React, { useEffect, useState } from "react";
import 'react-circular-progressbar/dist/styles.css';
import Breadcrumbs from './Breadcrumbs';
import TaskProgressChart from './TaskProgressChart'; 
import TaskCompletionPie from './TaskCompletionPie'; 
import TaskForm from "./TaskForm";
import Task from "./Task";
import { FaInstagram, FaFacebook, FaTwitter, FaDollarSign } from 'react-icons/fa';
import { useSwipeable } from 'react-swipeable';
import ExpensesForm from './ExpensesForm';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ExpensesList from './ExpensesList';

import { FaTimes, FaCar, FaHome, FaUtensils, FaBell, FaMusic, FaPaw, FaGift, FaTshirt, FaStore, FaTooth, FaHeartbeat, FaBus, FaCreditCard, FaPlane, FaPlus } from 'react-icons/fa';

function AppUI() {
  // Estados principales
  const [showChart, setShowChart] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false); // Estado para el tutorial
  const [showNewIncome, setShowNewIncome] = useState(false);
  const [tasks, setTasks] = useState([]);
  const today = new Date().toLocaleDateString();
  const [showExpenses, setShowExpenses] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [incomes, setIncomes] = useState([]);

  // Estados para el formulario de ingreso
  const [incomeAmount, setIncomeAmount] = useState('');
  const [incomeNote, setIncomeNote] = useState('');
  const [incomeCategory, setIncomeCategory] = useState('');

  // Cargar tareas desde localStorage
  useEffect(() => {
    const savedTasks = JSON.parse(localStorage.getItem('tasks'));
    if (savedTasks && savedTasks.length > 0) {
      setTasks(savedTasks);
    }
  }, []);

  // Guardar tareas en localStorage
  useEffect(() => {
    if (tasks.length > 0) {
      localStorage.setItem('tasks', JSON.stringify(tasks));
    } else {
      localStorage.removeItem('tasks');
    }
  }, [tasks]);

  // Cargar gastos desde localStorage
  useEffect(() => {
    const savedExpenses = JSON.parse(localStorage.getItem('expenses'));
    if (savedExpenses && savedExpenses.length > 0) {
      setExpenses(savedExpenses);
    }
  }, []);

  // Guardar gastos en localStorage
  useEffect(() => {
    if (expenses.length > 0) {
      localStorage.setItem('expenses', JSON.stringify(expenses));
    } else {
      localStorage.removeItem('expenses');
    }
  }, [expenses]);

  // Cargar ingresos desde localStorage
  useEffect(() => {
    const savedIncomes = JSON.parse(localStorage.getItem('incomes'));
    if (savedIncomes && savedIncomes.length > 0) {
      setIncomes(savedIncomes);
    }
  }, []);

  // Guardar ingresos en localStorage
  useEffect(() => {
    if (incomes.length > 0) {
      localStorage.setItem('incomes', JSON.stringify(incomes));
    } else {
      localStorage.removeItem('incomes');
    }
  }, [incomes]);

  // Función para agregar una tarea
  function addTask(name) {
    if (name.trim() === '') return;
    const newTask = {
      id: uuidv4(),  // Genera un ID único
      name,
      done: false,
      completedAt: null
    };
    setTasks(prev => [...prev, newTask]);

    // Animación para el botón de agregar (si aplica)
    const addButton = document.querySelector('.add-button.new-income-btn');
    if (addButton) {
      addButton.classList.add('rotate');
      setTimeout(() => addButton.classList.remove('rotate'), 300);
    }
  }

  // Función para eliminar una tarea
  function removeTask(id) {
    setTasks(prev => prev.filter(task => task.id !== id));
  }

  // Función para actualizar el estado de una tarea
  function updateTaskDone(id, newDone) {
    setTasks(prev => prev.map(task =>
      task.id === id ? { ...task, done: newDone, completedAt: newDone ? new Date().toISOString() : null } : task
    ));
  }

  // Función para renombrar una tarea
  function renameTask(id, newName) {
    if (newName.trim() === '') return;
    setTasks(prev => prev.map(task =>
      task.id === id ? { ...task, name: newName } : task
    ));
  }

  // Cálculos para el progreso de tareas
  const numberComplete = tasks.filter(t => t.done).length;
  const numberTotal = tasks.length;

  // Función para obtener un mensaje basado en el progreso
  function getMessage() {
    const percentage = numberTotal > 0 ? (numberComplete / numberTotal) * 100 : 0;
    if (percentage === 0) {
      return 'Try to do at least one! 🙏';
    }
    if (percentage === 100) {
      return 'Nice job for today! 🏝';
    }
    return 'Keep it going 💪🏻';
  }

  // Funciones para manejar el swipe
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (!showExpenses) {
        setShowExpenses(true);
      }
    },
    onSwipedRight: () => {
      if (showExpenses) {
        setShowExpenses(false);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  // Agregar función unificada para Income y Expense
  const addEntry = (entry) => {
    if (entry.type === 'Income') {
      setIncomes((prevIncomes) => {
        const updatedIncomes = [...prevIncomes, entry];
        localStorage.setItem('incomes', JSON.stringify(updatedIncomes));
        return updatedIncomes;
      });
    } else {
      setExpenses((prevExpenses) => {
        const updatedExpenses = [...prevExpenses, entry];
        localStorage.setItem('expenses', JSON.stringify(updatedExpenses));
        return updatedExpenses;
      });
    }
  };

  // Función para manejar el final del drag and drop
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedTasks = Array.from(tasks);
    const [reorderedItem] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, reorderedItem);

    setTasks(reorderedTasks);
  };
  // Función para activar/desactivar tutorial
  const toggleTutorial = () => setShowTutorial(!showTutorial); 
  // Definir las migas de pan y los mensajes para cada pantalla
  // Mensaje específico del tutorial según la pantalla actual
  const tutorialMessage = showExpenses 
  ? (
    <div className="tutorial-content">
      <h2 style={{ color: "#007AFF", fontSize: "24px" }}>💰 Manage Your Finances</h2>
      <p style={{ fontSize: "18px", color: "#555" }}>
        Use this screen to keep track of your income and expenses. Let’s walk through the steps to manage your finances effectively:
      </p>
      

      <div className="tutorial-step">
        <h3 style={{ color: "#34C759" }}>1️⃣ Add Income</h3>
        <p>Tap on <strong>Add Income</strong> to record your earnings, like:</p>
        <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
          <li>💵 Salary</li>
          <li>🏠 Rental income</li>
          <li>💰 Side hustle earnings</li>
        </ul>
      </div>
      <div className="tutorial-step">
        <h3 style={{ color: "#FF3B30" }}>2️⃣ Add Expense</h3>
        <p>Tap on <strong>Add Expense</strong> to open a drawer where you can record your spending. Here’s how to use it:</p>
        
        <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
          <li><strong>💵 Amount:</strong> Enter the amount of money you spent. $50.</li>
          
          <li><strong>📋 Category Type :</strong> <strong>Type</strong> or <strong>Select</strong> a category for your expense. Use emojis to personalize it! or write it like this <strong>FwD🍔</strong></li>
          <p>For example your own category:</p>
          <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
            <li>🚗 <strong>GSO</strong> for Gasoline my son</li>
            <li>🍔 <strong>FwD</strong> for Food whit Dr. Frank</li>
            <li>🏠 <strong>REN</strong> for Rent</li>
          </ul>  
        
          <p>For example:</p>
          <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
            <li><FaCar/> for Gasoline</li>
            <li><FaUtensils/> for Food</li>
            <li><FaPaw/> for pets</li>
          </ul>
              
        </ul>
        <p>Once you’ve entered the amount and category type 1 or 2, tap <strong>Add</strong> to save the expense. Each expense will be listed in your monthly expense list.</p>
      </div>

      <div className="tutorial-step">
        <h3 style={{ color: "#FF9500" }}>3️⃣ Visualize with Charts</h3>
        <p>View your spending and income with easy-to-read charts. This helps you understand your budget at a glance! These categories will group together and show the total progress</p>
      </div>

      <p style={{ fontSize: "18px", color: "#007AFF", textAlign: "center", marginTop: "20px" }}>Start managing your finances like a pro! 💼</p>
    </div>
  )
  : (
    <div className="tutorial-content">
      <h2 style={{ color: "#007AFF", fontSize: "24px" }}>📋 Welcome to Your Daily Tasks!</h2>
      <p style={{ fontSize: "18px", color: "#555" }}>
        Ready to make progress on your goals? Follow these easy steps to start adding and tracking tasks you want to accomplish today!
      </p>
      
      <div className="tutorial-step">
        <h3 style={{ color: "#FF9500" }}>1️⃣ Type Your Task</h3>
        <p>In the <strong>Your Next Task</strong> field, type any daily goal you want to complete today. Think of things like:</p>
        <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
          <li>✅ Drink 1 liter of water</li>
          <li>📖 Read for 15 minutes</li>
          <li>🧘🏻 Meditate for 10 minutes</li>
          <li>🏃🏽‍♂️ Go for a 20-minute walk</li>
        </ul>
      </div>

      <div className="tutorial-step">
        <h3 style={{ color: "#34C759" }}>2️⃣ Add the Task to Your List</h3>
        <p>Press the <strong><span style={{ color: "#007AFF", fontSize: "20px" }}>+</span> button</strong> to add your task to today’s list. Each task will appear below, in a neat and organized list with:</p>
        <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
          <li>📅 The task name</li>
          <li>📅 A checkbox to mark it complete</li>
          <li>🔄 Option to reorder tasks by dragging</li>
        </ul>
      </div>

      <div className="tutorial-step">
        <h3 style={{ color: "#5856D6" }}>3️⃣ Check Off Tasks as You Complete Them</h3>
        <p>As you finish each task, tap the <strong>checkbox</strong> next to it to mark it as complete. Completed tasks will have a cool line-through effect so you can see your progress!</p>
      </div>

      <div className="tutorial-step">
        <h3 style={{ color: "#FF3B30" }}>4️⃣ Track Your Progress with the Donut Chart</h3>
        <p>Each completed task updates your progress in the donut chart. Watch the circle fill up as you accomplish more! The goal is to reach 100% by the end of the day. 🌟</p>
      </div>

      <p style={{ fontSize: "18px", color: "#007AFF", textAlign: "center", marginTop: "20px" }}>Now you’re ready to get started! Add tasks, check them off, and see your progress grow! 🎉</p>
    </div>
  );

  // Cerrar tutorial al hacer clic fuera del mensaje
  const closeTutorial = (e) => {
    if (e.target.classList.contains('tutorial-overlay')) {
      setShowTutorial(false);
    }
  };


  // Función para eliminar entradas (ingresos o gastos) del localStorage 
  const handleDeleteEntry = (id, type) => {
    const updatedEntries = (type === 'Expense' ? expenses : incomes).filter(entry => entry.id !== id);
    if (type === 'Expense') {
      setExpenses(updatedEntries);
      localStorage.setItem('expenses', JSON.stringify(updatedEntries)); // Actualiza localStorage solo con las entradas necesarias
    } else {
      setIncomes(updatedEntries);
      localStorage.setItem('incomes', JSON.stringify(updatedEntries)); // Actualiza localStorage solo con las entradas necesarias
    }
  };
  
  return (
    <div className={`app-container ${showNewIncome ? 'show-new-income' : ''}`} {...swipeHandlers}>
      {/* Header Principal */}
      <header className="app-header">
        <div className="social-icons">
          <a href="https://www.instagram.com/mimejorversiondiaria/" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={24} />
          </a>
        </div>
        <div className="header-right">
          <div className="welcome-text">Welcome</div>
          <button onClick={toggleTutorial} className="tutorial-btn">Tutorial</button>
          <button 
            className="add-button expenses-btn" 
            onClick={() => setShowExpenses(true)}
          >
            <FaDollarSign size={20} />
          </button>
        </div>
      </header>
      
      {/* Mostrar ExpensesForm o Task List basado en showExpenses */}
      {/* Transición de pantallas */}
      <TransitionGroup component={null}>
      {showExpenses ? (
        <CSSTransition classNames="fade" timeout={300}>
        <ExpensesForm 
          onClose={() => setShowExpenses(false)} 
          onAddEntry={addEntry}
          incomes={incomes}
          expenses={expenses}
          onDelete={handleDeleteEntry}
          setExpenses={setExpenses} // Pasa setExpenses 
          setIncomes={setIncomes} // Pasa setIncomes
        />
        </CSSTransition>
      ) : (
        <CSSTransition classNames="fade" timeout={300}>
        <main>
          <h2 style={{ fontStyle: 'italic' }}>Today: {today}</h2>
          <h1>My Best Version</h1>
          <h3 style={{ fontStyle: 'italic' }}>{numberComplete}/{numberTotal} Completes</h3>
          <h2 style={{ fontStyle: 'italic' }}>{getMessage()}</h2>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            <TaskCompletionPie percentage={numberTotal > 0 ? (numberComplete / numberTotal) * 100 : 0} simbol="%" />
          </div>
          <TaskForm onAdd={(name) => addTask(name)} />

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="tasks">
              {(provided) => (
                <div className="task-list" {...provided.droppableProps} ref={provided.innerRef}>
                  {tasks.map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="task-item"
                        >
                          <Task
                            {...task}
                            onRename={(newName) => renameTask(task.id, newName)}
                            onTrash={() => removeTask(task.id)}
                            onToggle={(done) => updateTaskDone(task.id, done)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </main>
        </CSSTransition>
      )}
      </TransitionGroup>
      {/* Mostrar el overlay del tutorial con el mensaje específico */}
      {showTutorial && (
        <div className="tutorial-overlay" onClick={closeTutorial}>
          <div className="tutorial-box">
            {tutorialMessage}
          </div>
        </div>
      )}
    </div>
  );
}

export { AppUI };
