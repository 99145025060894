// src/App/TaskCompletionPie.js

import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

const TaskCompletionPie = ({ percentage, simbol }) => {
  return (
    <div style={{ width: '100px', height: '100px' }}>
      <CircularProgressbar value={percentage} text={`${Math.round(percentage)}${simbol}`} />
    </div>
  );
};

export default TaskCompletionPie;
